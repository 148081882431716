.App {
  text-align: center;
  background: #EBF6F2;
  width: 100%;
  height: 100%;
}

.Content-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Content, .Header {
  color: black;
  position: absolute;
  z-index: 10;
  font-weight: 300;
}

.Content-title {
  font-size: 40px;
}

.Content-inline {
  display: inline;
}

.Content-bold {
  font-weight: 500;
}

.Content-icon-link {
  text-decoration: none;
  color: black;
}

.Header {
  width: 98%;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
  z-index: 10;
}

.Flag {
  overflow: hidden;
  width: 30px;
  padding-right: 1px;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  background-color: white;
  border-radius: 3px;
  border: none;
}

@media only screen and (max-width: 600px) {
  .Content, .Header {
    margin: 0 10px;
  }

  .Header {
    width: 96%;
    margin-top: 1.5%;
  }
}